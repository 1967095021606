import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import Icon1 from '../assets/images/icons/1.svg'
import Icon2 from '../assets/images/icons/2.svg'
import Icon3 from '../assets/images/icons/3.svg'
import Icon4 from '../assets/images/icons/4.svg'
import Icon5 from '../assets/images/icons/5.svg'
import Icon6 from '../assets/images/icons/6.svg'
import Bag from '../assets/images/icons/bag.svg'
import Headset from '../assets/images/icons/headset.svg'
import Wallet from '../assets/images/icons/wallet.svg'
import PlayCircle from '../assets/images/icons/play-circle.svg'
import RightArrowLg from '../assets/images/icons/arrow-lg-right.svg'
import RightArrowLgBlack from '../assets/images/icons/arrow-black-right.svg'
import RightArrowXl from '../assets/images/icons/arrow-xl-right.svg'
import Chart1 from '../assets/images/chart1.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import CountUp from 'react-countup'
import Banner from '../assets/images/banner.png'
import BgRings from '../assets/images/banner-rings.svg'
import ArrowSmall from '../assets/images/icons/arrow-sm.svg'
import Faq from './Faq';
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import CardStack from './CardStack'
const images = require.context('../assets/images/clients', true);
const imageList = images.keys().map(image => images(image));

function Home() {
  const countersRef = useRef([]);
  useEffect(() => {
    AOS.init();
    var splide = new Splide( '.splide.logos', {
      type   : 'loop',
      perPage: 4,
      perMove: 1,
      arrows: false,
      autoplay: true,
      interval: 3000,
      speed: 800,
      easing: 'linear',
      pagination: false,
      breakpoints: {
        576: {
          perPage: 3,
        },
      }
    } );
    
    splide.mount();
  }, []);  
  return (
    <>
    <div className="bg-[#e2edf3] max-[767px]:px-3 lg:pt-8 first-section">
      <div className='max-w-screen-lg mx-auto flex flex-col'>
          <div className='flex flex-row items-center text-center gap-6 md:gap-x-28 lg:gap-x-40 justify-center [&_.counter]:text-[20px] md:[&_.counter]:text-3xl [&_small]:text-xs md:[&_small]:text-sm my-10' data-aos="fade-up">
            <div>
              <div className='counter font-sfpdR md:mb-2' ><CountUp end={1046275338}  duration= {5} start={0} /></div>
              <small className='font-sfpdM uppercase'>Wallets available</small>
            </div>
            <div>
              <div className='counter font-sfpdR md:mb-2' ><CountUp end={182145}  duration= {5} start={0} /></div>
              <small className='font-sfpdM uppercase'>USERS</small>
            </div>
            <div>
              <div className='counter font-sfpdR md:mb-2' ><CountUp end={139}  duration= {5} start={0} /></div>
              <small className='font-sfpdM uppercase'>Average return</small>
            </div>
          </div>
          <div className='max-w-screen-xl mx-auto text-center lg:pt-2'>
            <h1 className=' font-pfR leading-tight max-[767px]:text-[28px] md:px-20 lg:px-8 md:text-[35px] lg:text-[49px]' data-aos="fade-up" data-aos-delay="200">Easiest wins in crypto</h1>
            <div className="text-[26px] max-w-3xl leading-tight mt-4">Hyperion is the simplest + easiest platform to generate the best returns in crypto.</div>
            <h2 className='font-pfR mt-10 max-[767px]:text-[22px] md:text-[27px] lg:text-[31px] !border-[1px] border-black/70 inline-block pt-2 pb-4 px-12 rounded-full' id="startAnimation" data-aos="fade-up" data-aos-delay="300">One click profit</h2>
          </div>
          <div className='md:pb-8'>
            <div className='table mx-auto mt-3 mb-0 absolute inset-x-0' data-aos="fade-down" data-aos-delay="400">
              <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="51" viewBox="0 0 14.828 51">
                <g id="_2561329_arrow_arrow_down_down_arrow_down_down_arrow_arrow_down" data-name="2561329_arrow_arrow_down_down_arrow_down_down_arrow_arrow_down" transform="translate(-4.586 30)">
                  <line id="Line_503" data-name="Line 503" y2="49" transform="translate(12 -29)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                  <path id="Path_1258" data-name="Path 1258" d="M18,14l-6,6L6,14" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                </g>
              </svg>
            </div>
          </div>   
          {/* <div className='md:max-w-[75%] mx-auto xl:max-w-none img' data-aos="fade-up" data-aos-delay="500">
            <img src={Banner} />
            </div> */}
      </div>
      <CardStack/>
    </div>
    <div className='bg-[#252726] pt-16 pb-40 -mt-32 max-[767px]:px-3'>
      <div className='pt-14 pb-16 lg:py-16 xl:py-20 max-w-screen-xl mx-auto items-center'>
        <div className='lg:grid lg:grid-cols-12 text-white [&_p]:opacity-[.7] lg:[&_p]:text-[17px] [&_p]:leading-tight [&_h3]:text-[#A6D7F5] xl:[&_h3]:text-[25px] [&_h3]:font-pfR [&_h3]:mb-2 lg:[&_h3]:mb-4 [&_p]:font-sfpdR ml-auto lg:pl-10 max-[991px]:gap-2 max-[767px]:flex md:grid max-[767px]:flex-col md:px-6 lg:px-12 px-0'>
          <div className='col-span-8 col-start-3 grid md:grid-cols-2 gap-8 lg:gap-y-20 gap-x-12' data-aos="fade-up">
            <div className='grid grid-cols-12'>
              <div className='col-span-2'></div><div className='col-span-10 pl-3 lg:pl-6 text-[26px] font-sfpdR'>Best Returns</div>
            </div>
          </div>
          <div className='col-span-8 col-start-3 grid md:grid-cols-2 gap-8 lg:gap-y-20 gap-x-12'>
            <div className='grid grid-cols-12 items-center' data-aos="fade-up">
              <div className='col-span-2'>
                <img src={Icon1} className='mx-auto'/>
              </div>
              <div className='col-span-10 pl-3 lg:pl-6'>
                <h3>Top Strategies</h3>
                <p>Access the world`s best trading strategies that fits your risk preference in real-time.</p>
              </div>
            </div>
            <div className='grid grid-cols-12 items-center' data-aos="fade-up">
                <div className='col-span-2'>
                  <img src={Icon2} className='mx-auto'/>
                </div>
                <div className='col-span-10 pl-3 lg:pl-6'>
                  <h3>Full Analysis</h3>
                  <p>We analyze and rank each strategy through 25 performance and risk metrics, making sure you understand the potential and the risk of each strategy.</p>
                </div>
            </div>
          </div>
          <div className='col-span-12 lg:py-10 my-4 md:my-0' data-aos="fade-up"><hr className='xl:border-t lg:border-b border-[#4A5A62]'/></div>
          <div className='col-span-8 col-start-3 grid md:grid-cols-2 gap-8 lg:gap-y-20 gap-x-12 md:mb-3' data-aos="fade-up">
            <div className='grid grid-cols-12'>
              <div className='col-span-2'></div><div className='col-span-10 pl-3 lg:pl-6 text-[26px] font-sfpdR'>Best Execution</div>
            </div>
          </div>
          <div className='col-span-8 col-start-3 grid md:grid-cols-2 gap-8 lg:gap-y-20 gap-x-12 relative'>
                <div className='grid grid-cols-12 items-center' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
                  <div className='col-span-2'>
                    <img src={Icon3} className='mx-auto'/>
                  </div>
                  <div className='col-span-10 pl-3 lg:pl-6'>
                    <h3>User friendly interface</h3>
                    <p>Simply connect your wallet, choose the amount and the strategy, and we take care of the rest.</p>
                  </div>
                </div>
                <div className='grid grid-cols-12 items-center' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
                  <div className='col-span-2'>
                    <img src={Icon4} className='mx-auto'/>
                  </div>
                  <div className='col-span-10 pl-3 lg:pl-6'>
                    <h3>Fastest Execution</h3>
                    <p>Our system has been built from the ground up to assure the fastest trading execution.</p>
                  </div>
                </div>
          </div>
          <div className='col-span-12 lg:py-10 my-4 md:my-0'><hr className='xl:border-t lg:border-b border-[#4A5A62]' data-aos="fade-up"/></div>
          <div className='col-span-8 col-start-3 grid md:grid-cols-2 gap-8 lg:gap-y-20 gap-x-12 mb-1' data-aos="fade-up">
            <div className='grid grid-cols-12'>
              <div className='col-span-2'></div><div className='col-span-10 pl-3 lg:pl-6 text-[26px] font-sfpdR'>Best Security</div>
            </div>
          </div>
          <div className='col-span-8 col-start-3 grid md:grid-cols-2 gap-8 lg:gap-y-20 gap-x-12'>
            <div className='grid grid-cols-12 items-center' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
              <div className='col-span-2'>
                <img src={Icon5} className='mx-auto'/>
              </div>
              <div className='col-span-10 pl-3 lg:pl-6'>
                <h3>Secure Transactions</h3>
                <p>Ensure your investments are safe with top-tier security measures and protocols.</p>
              </div>
            </div>
            <div className='grid grid-cols-12 items-center' data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
              <div className='col-span-2'>
                <img src={Icon6} className='mx-auto'/>
              </div>
              <div className='col-span-10 pl-3 lg:pl-6'>
                <h3>Transparent Operations</h3>
                <p>Enjoy full transparency in all transactions and investment strategies, fostering trust and confidence.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center mt-16'><Link to='#' className='bg-[#A6D7F5] rounded-full min-w-[190px] justify-center py-[1.2rem] text-sm font-sfpdM inline-flex items-center gap-2 [&_img]:hover:translate-x-4'>SIGN UP TODAY <img src={ArrowSmall} className='transition-all duration-500'/></Link></div>
      </div>
      <div className='max-w-screen-xl mx-auto mb-14'>
        <h2 className='text-[22px] text-center font-sfpdR text-[#A6D7F5]'>APP &nbsp;DEMO</h2> 
      </div>
    </div>
    <div className='bg-[#E2EDF3] pb-10 lg:pb-20 xl:pb-20 px-3'>
      <div className='max-w-screen-xl mx-auto'>
        <div className='md:w-[650px] md:h-[400px] lg:w-[800px] lg:h-[455px] py-20 lg:py-0 mx-auto rounded-[32px] flex items-center justify-center bg-[#000000] -top-44 relative'>
          <img src={PlayCircle}/>
        </div>   
        <div className='flex items-center justify-end -mt-32 lg:-mt-36 xl:pr-14'>
          <h3 className='text-[24px] max-w-80 leading-tight mr-5 font-pfR' data-aos="fade-right">For more knowledge, join our community on Discord</h3>
          <Link className='min-w-[60px] h-[60px] after:bg-black lg:min-w-[150px] lg:h-[150px] xl:min-w-[200px] xl:h-[200px] rounded-full inline-flex items-center justify-center cursor-pointer relative after:transition-all after:duration-[500ms] hover:after:scale-[1.1] after:border after:border-black after:rounded-full after:inset-0 after:absolute [&_img]:hover:left-10' data-aos="fade-right" data-aos-duration="4000">
            <img src={RightArrowLg} className='relative transition-all duration-[500ms] left-0 max-w-10 lg:max-w-none z-10'/>
          </Link>
        </div>
      </div>
    </div>
    <div className='bg-[#252726] py-10 lg:py-20 px-4 lg:px-5 xl:px-0'>
      <div className='max-w-screen-xl mx-auto'>
        <div className='flex flex-col lg:grid gap-y-4 lg:gap-0 lg:grid-cols-12 lg:items-center'>
          <h2 className='font-pfR text-4xl lg:text-5xl leading-tight col-span-1 text-white'>FAQs</h2>
          <div className='accordions [&_h3]:text-[18px] xl:[&_h3]:text-[21px] [&_h3]:cursor-pointer [&_h3]:font-sfpdR col-span-7 flex flex-col gap-y-2 xl:gap-y-1 lg:pl-16 text-white/70'>
            <Faq/>            
          </div>
          <div className='flex items-center col-span-4 gap-4 lg:gap-8 justify-end mt-2 lg:mt-0'>
            <h3 className='text-[16px] md:text-[22px] whitespace-nowrap font-sfpdR text-white/70 leading-tight'>Start Investing/<br/>Sign up today</h3>
            <div className='min-w-[60px] h-[60px] after:bg-[#A6D7F5] lg:min-w-[150px] lg:h-[150px] xl:min-w-[200px] xl:h-[200px] rounded-full inline-flex items-center justify-center cursor-pointer relative after:transition-all after:duration-[500ms] hover:after:scale-[1.1] after:border after:border-[#A6D7F5] after:rounded-full after:inset-0 after:absolute [&_img]:hover:left-10'>
              <img src={RightArrowLgBlack} className='relative transition-all duration-[500ms] left-0 max-w-10 lg:max-w-none z-10'/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='bg-[#CEE8F6] py-10 lg:py-14 xl:py-28 px-5 lg:px-0'>
      <div className='max-w-screen-xl mx-auto text-center'>
        <h2 className='text-4xl lg:text-5xl leading-tight font-pfR'>Core Team</h2>
        <p className='lg:text-[25px] xl:text-[35px] my-4 lg:my-10 lg:max-w-[70%] mx-auto leading-tight font-pfR'>A young innovative team from your <span className='font-pfB'>favorite fortune 500</span> company that wants to let your grandmother generate like the world’s best traders.</p>
        <div className="splide logos mb-10">
          <div className="splide__track">
            <ul className="splide__list">
            {imageList.map((image, index) => (
              <li className="splide__slide flex justify-center px-2 md:px-0"><img key={index} src={image} /></li>
            ))}
            </ul>
          </div>
        </div>
        <Link className='text-[16px] lg:text-[18px] xl:text-[22px] border border-black bg-black hover:bg-transparent text-white hover:text-black rounded-full w-[180px] h-[50px] lg:w-[227px] xl:w-[327px] lg:h-[60px] xl:h-[90px] inline-flex items-center justify-center mt-2 !transition-all duration-[500ms] font-sfpdM'>MEET THEM</Link>
      </div>
    </div>
    </>
  )
}

export default Home