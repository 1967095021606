import React from 'react';
import AmbarishKrishnamurthy from '../assets/images/team/ambarish-krishnamurthy.png'
import JonathanBouchard from '../assets/images/team/jonathan-bouchard.png'
import JaydeepGoyal from '../assets/images/team/jaydeep-goyal.png'
import NancyBathla from '../assets/images/team/nancy-bathla.png'
import Client1 from '../assets/images/client-icon/1.png'
import Client2 from '../assets/images/client-icon/2.png'
import Client3 from '../assets/images/client-icon/3.png'
import Client4 from '../assets/images/client-icon/4.png'
import Client5 from '../assets/images/client-icon/5.png'
import Client6 from '../assets/images/client-icon/6.png'
import Client7 from '../assets/images/client-icon/7.png'
function Team() {
  return (
    <>
        <div className='bg-[#e2edf3] py-10 h-full'>
          <div className='max-w-screen-xl mx-auto text-center mb-10'>
            <h2 className='text-4xl lg:text-5xl leading-tight font-pfR mb-12' data-aos="fade-up">Core Team</h2>
            <div className='grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 text-center sm:gap-x-8 lg:gap-x-8 xl:gap-x-16 leading-tight [&_h3]:mb-3 px-10 xl:px-0 gap-y-16 sm:gap-y-10 lg:gap-y-0'>
              <div className='flex flex-col items-center'>
                <div className='w-48 h-48 overflow-hidden rounded-full inline-flex justify-center items-center mb-4'>
                  <img src={AmbarishKrishnamurthy} className='w-full h-full object-contain'/>
                </div>
                <h3 className='text-[22px] font-sfpdB'>Ambarish Krishnamurthy</h3>
                <p className='font-sfpdR flex-1'>Ambarish is a former VP technology specializing in low-latency, high frequency trading systems across various asset classes, including Equities, Options, Crypto, and FX markets. He has built large-scale ultra-low latency smart order routers and order management systems for major clients such as BlackRock, Citadel, Tower Research, and Fidelity.</p>
                <div className='flex justify-center flex-col items-center mt-4'>
                  <div className='flex items-center gap-6 justify-center'>
                    <img src={Client1} className='h-14'/>
                    <img src={Client2} className='h-10'/>
                  </div>
                  <div className='mt-2'><img src={Client3} className='h-18'/></div>
                </div>
              </div>
              <div className='flex flex-col items-center'>
                <div className='w-48 h-48 overflow-hidden rounded-full inline-flex justify-center items-center mb-4'>
                  <img src={JonathanBouchard}/>
                </div>
                <h3 className='text-[22px] font-sfpdB'>Jonathan Bouchard</h3>
                <p className='font-sfpdR flex-1'>Jonathan was the youngest 5-star stockbroker ever hired by BMO at the age of 19 years old, he managed a total of $1.5B in asset and broke the bank’s record for the most sales closed with $72M in one day, $129M in 90 days at the age of 22 years old</p>
                <div className='flex justify-center mt-4'><img src={Client4} className='h-12'/></div>
              </div>
              <div className='flex flex-col items-center'>
                <div className='w-48 h-48 overflow-hidden rounded-full inline-flex justify-center items-center mb-4'>
                  <img src={JaydeepGoyal}/>
                </div>
                <h3 className='text-[22px] font-sfpdB'>Jaydeep Goyal</h3>
                <p className='font-sfpdR flex-1'>Jaydeep Goyal is a Lead Quantitative Researcher and Developer at Hyperion. He is a former data analyst for Wells Fargo and has previously worked for the defence department of the Indian Government. He graduated from IIT Kanpur, achieving a JEE Advanced rank of 640 out of 1.5 million students (top 0.04%). His expertise extends to probability, statistics, and stochastic calculus, honed through participation in the International Mathematical Olympiad (IMO).</p>
                <div className='flex justify-center mt-4'><img src={Client5} className='h-12'/></div>
              </div>
              <div className='flex flex-col items-center'>
                <div className='w-48 h-48 overflow-hidden rounded-full inline-flex justify-center items-center mb-4'>
                  <img src={NancyBathla}/>
                </div>
                <h3 className='text-[22px] font-sfpdB'>Nancy Bathla</h3>
                <p className='font-sfpdR flex-1'>Nancy is a lead engineer and former executive at Apple and Accenture. She has led Apple's AI and robotics initiatives and is known as a reference in the AI and project management industry. She played a key role in leading the implementation of Apple Pay, Gen AI, and LLM for Apple.</p>
                <div className='flex items-center gap-6 justify-center mt-4'>
                  <img src={Client6} className='h-10'/>
                  <img src={Client7} className='h-8'/>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Team