import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Graph1 from '../assets/images/graph01.png'
import Graph2 from '../assets/images/graph02.png'
import Graph11 from '../assets/images/graph-1.png'
import Graph22 from '../assets/images/graph-2.png'
import Banner2 from '../assets/images/banner-2.png'
import Banner3 from '../assets/images/banner-3.png'
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const CardStack = () => {
  
  useEffect(() => {
    //new Splide( '.splide' ).mount();
    var splide = new Splide( '.bannerSlider', {
      type     : 'loop',
      arrows: false,
      // focus : 'center',
      autoWidth: true,
    } );
    
    splide.mount();
  }, []);

  return (
      <>
        <div className='max-w-screen-2xl mx-auto'>
          <section className="splide bannerSlider">
            <div className="splide__track py-20">
              <ul className="splide__list [&_.count]:absolute [&_.count]:top-3 [&_.count]:left-3 [&_.count]:rounded-full [&_.count]:min-w-[34px] [&_.count]:h-[34px] [&_.count]:text-black [&_.count]:flex [&_.count]:items-center [&_.count]:justify-center [&_.count]:">
                <li className="splide__slide px-2 !w-[380px] [&_.popup]:hover:visible hover:[&_.popup]:!opacity-100">
                  <div className='text-sm font-sfpdM uppercase mb-1'>Low Risk</div>
                  <div className='bg-black rounded-xl min-h-[188px] flex items-center justify-center relative'>
                    <div className='count bg-[#A6F5B6]'>97</div>
                    <img src={Graph1} className=' w-[380px]'/>
                  </div>
                  <div className='bg-black rounded-xl p-5 absolute max-[576px]:w-[370px] w-[380px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-50 invisible opacity-0 popup transition-all'>
                    <div className='count bg-[#A6F5B6] !top-0 !left-0 !relative inline-flex !text-xs !min-w-[26px] max-w-[26px]  max-h-[26px]'>97</div>
                    <img src={Graph11} className='my-5'/>
                    <div className='text-xs font-sfpdR text-white mt-2 mb-2'>0X5C0....5950C55</div>
                    <div className='grid grid-cols-12'>
                      <div className='col-span-3'>
                        <div className='text-[#A6D7F5] text-[11px]'>$12,948.47</div>
                        <div className='text-white/40 text-[10px]'>APPT</div>
                      </div>
                      <div className='col-span-6'>
                        <div className='text-[#A6D7F5] text-[11px]'>2 days ago</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                      <div className='col-span-3 text-end'>
                        <div className='text-[#A6D7F5] text-[11px]'>$507,582.06</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="splide__slide px-2 !w-[380px] [&_.popup]:hover:visible hover:[&_.popup]:!opacity-100">
                  <div className='text-sm font-sfpdM uppercase mb-1'>Top Tier</div>
                  <div className='bg-black rounded-xl min-h-[188px] flex items-center justify-center relative'>
                    <div className='count bg-[#FB4D4D]'>40</div>
                  <img src={Graph2} className=' w-[380px]'/>
                  </div>
                  <div className='bg-black rounded-xl p-5 absolute max-[576px]:w-[370px] w-[380px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-50 invisible opacity-0 popup transition-all'>
                    <div className='count bg-[#FB4D4D] !top-0 !left-0 !relative inline-flex !text-xs !min-w-[26px] max-w-[26px]  max-h-[26px]'>40</div>
                    <img src={Graph22} className='my-5'/>
                    <div className='text-xs font-sfpdR text-white mt-2 mb-2'>0X5C0....5950C55</div>
                    <div className='grid grid-cols-12'>
                      <div className='col-span-3'>
                        <div className='text-[#A6D7F5] text-[11px]'>$12,948.47</div>
                        <div className='text-white/40 text-[10px]'>APPT</div>
                      </div>
                      <div className='col-span-6'>
                        <div className='text-[#A6D7F5] text-[11px]'>2 days ago</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                      <div className='col-span-3 text-end'>
                        <div className='text-[#A6D7F5] text-[11px]'>$507,582.06</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="splide__slide px-2 !w-[380px] [&_.popup]:hover:visible hover:[&_.popup]:!opacity-100">
                  <div className='text-sm font-sfpdM uppercase mb-1'>Over 10X</div>
                  <div className='bg-black rounded-xl min-h-[188px] flex items-center justify-center relative'>
                    <div className='count bg-[#A6F5B6]'>97</div>
                    <img src={Graph1} className=' w-[380px]'/>
                  </div>
                  <div className='bg-black rounded-xl p-5 absolute max-[576px]:w-[370px] w-[380px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-50 invisible opacity-0 popup transition-all'>
                    <div className='count bg-[#A6F5B6] !top-0 !left-0 !relative inline-flex !text-xs !min-w-[26px] max-w-[26px]  max-h-[26px]'>97</div>
                    <img src={Graph11} className='my-5'/>
                    <div className='text-xs font-sfpdR text-white mt-2 mb-2'>0X5C0....5950C55</div>
                    <div className='grid grid-cols-12'>
                      <div className='col-span-3'>
                        <div className='text-[#A6D7F5] text-[11px]'>$12,948.47</div>
                        <div className='text-white/40 text-[10px]'>APPT</div>
                      </div>
                      <div className='col-span-6'>
                        <div className='text-[#A6D7F5] text-[11px]'>2 days ago</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                      <div className='col-span-3 text-end'>
                        <div className='text-[#A6D7F5] text-[11px]'>$507,582.06</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="splide__slide px-2 !w-[380px] [&_.popup]:hover:visible hover:[&_.popup]:!opacity-100">
                  <div className='text-sm font-sfpdM uppercase mb-1'>Meme Coins</div>
                  <div className='bg-black rounded-xl min-h-[188px] flex items-center justify-center relative'>
                    <div className='count bg-[#FB4D4D]'>40</div>
                    <img src={Graph2} className=' w-[380px]'/>
                  </div>
                  <div className='bg-black rounded-xl p-5 absolute max-[576px]:w-[370px] w-[380px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-50 invisible opacity-0 popup transition-all'>
                    <div className='count bg-[#FB4D4D] !top-0 !left-0 !relative inline-flex !text-xs !min-w-[26px] max-w-[26px]  max-h-[26px]'>40</div>
                    <img src={Graph22} className='my-5'/>
                    <div className='text-xs font-sfpdR text-white mt-2 mb-2'>0X5C0....5950C55</div>
                    <div className='grid grid-cols-12'>
                      <div className='col-span-3'>
                        <div className='text-[#A6D7F5] text-[11px]'>$12,948.47</div>
                        <div className='text-white/40 text-[10px]'>APPT</div>
                      </div>
                      <div className='col-span-6'>
                        <div className='text-[#A6D7F5] text-[11px]'>2 days ago</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                      <div className='col-span-3 text-end'>
                        <div className='text-[#A6D7F5] text-[11px]'>$507,582.06</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="splide__slide px-2 !w-[380px] [&_.popup]:hover:visible hover:[&_.popup]:!opacity-100">
                  <div className='text-sm font-sfpdM uppercase mb-1'>Medium Risk2</div>
                  <div className='bg-black rounded-xl min-h-[188px] flex items-center justify-center relative'>
                    <div className='count bg-[#A6F5B6]'>97</div>
                    <img src={Graph1} className='w-[380px]'/>
                  </div>
                  <div className='bg-black rounded-xl p-5 absolute max-[576px]:w-[370px] w-[380px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-50 invisible opacity-0 popup transition-all'>
                    <div className='count bg-[#A6F5B6] !top-0 !left-0 !relative inline-flex !text-xs !min-w-[26px] max-w-[26px]  max-h-[26px]'>97</div>
                    <img src={Graph11} className='my-5'/>
                    <div className='text-xs font-sfpdR text-white mt-2 mb-2'>0X5C0....5950C55</div>
                    <div className='grid grid-cols-12'>
                      <div className='col-span-3'>
                        <div className='text-[#A6D7F5] text-[11px]'>$12,948.47</div>
                        <div className='text-white/40 text-[10px]'>APPT</div>
                      </div>
                      <div className='col-span-6'>
                        <div className='text-[#A6D7F5] text-[11px]'>2 days ago</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                      <div className='col-span-3 text-end'>
                        <div className='text-[#A6D7F5] text-[11px]'>$507,582.06</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="splide__slide px-2 !w-[380px] [&_.popup]:hover:visible hover:[&_.popup]:!opacity-100">
                  <div className='text-sm font-sfpdM uppercase mb-1'>Long Term Investor</div>
                  <div className='bg-black rounded-xl min-h-[188px] flex items-center justify-center relative'>
                    <div className='count bg-[#FB4D4D]'>40</div>
                    <img src={Graph2} className=' w-[380px]'/>
                  </div>
                  <div className='bg-black rounded-xl p-5 absolute max-[576px]:w-[370px] w-[380px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-50 invisible opacity-0 popup transition-all'>
                    <div className='count bg-[#FB4D4D] !top-0 !left-0 !relative inline-flex !text-xs !min-w-[26px] max-w-[26px]  max-h-[26px]'>40</div>
                    <img src={Graph22} className='my-5'/>
                    <div className='text-xs font-sfpdR text-white mt-2 mb-2'>0X5C0....5950C55</div>
                    <div className='grid grid-cols-12'>
                      <div className='col-span-3'>
                        <div className='text-[#A6D7F5] text-[11px]'>$12,948.47</div>
                        <div className='text-white/40 text-[10px]'>APPT</div>
                      </div>
                      <div className='col-span-6'>
                        <div className='text-[#A6D7F5] text-[11px]'>2 days ago</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                      <div className='col-span-3 text-end'>
                        <div className='text-[#A6D7F5] text-[11px]'>$507,582.06</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="splide__slide px-2 !w-[380px] [&_.popup]:hover:visible hover:[&_.popup]:!opacity-100">
                  <div className='text-sm font-sfpdM uppercase mb-1'>High Volume</div>
                  <div className='bg-black rounded-xl min-h-[188px] flex items-center justify-center relative'>
                    <div className='count bg-[#A6F5B6]'>97</div>
                    <img src={Graph1} className=' w-[380px]'/>
                  </div>
                  <div className='bg-black rounded-xl p-5 absolute max-[576px]:w-[370px] w-[380px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-50 invisible opacity-0 popup transition-all'>
                    <div className='count bg-[#A6F5B6] !top-0 !left-0 !relative inline-flex !text-xs !min-w-[26px] max-w-[26px]  max-h-[26px]'>97</div>
                    <img src={Graph11} className='my-5'/>
                    <div className='text-xs font-sfpdR text-white mt-2 mb-2'>0X5C0....5950C55</div>
                    <div className='grid grid-cols-12'>
                      <div className='col-span-3'>
                        <div className='text-[#A6D7F5] text-[11px]'>$12,948.47</div>
                        <div className='text-white/40 text-[10px]'>APPT</div>
                      </div>
                      <div className='col-span-6'>
                        <div className='text-[#A6D7F5] text-[11px]'>2 days ago</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                      <div className='col-span-3 text-end'>
                        <div className='text-[#A6D7F5] text-[11px]'>$507,582.06</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="splide__slide px-2 !w-[380px] [&_.popup]:hover:visible hover:[&_.popup]:!opacity-100">
                  <div className='text-sm font-sfpdM uppercase mb-1'>Dummy Proof</div>
                  <div className='bg-black rounded-xl min-h-[188px] flex items-center justify-center relative'>
                    <div className='count bg-[#FB4D4D]'>40</div>
                    <img src={Graph2} className=' w-[380px]'/>
                  </div>
                  <div className='bg-black rounded-xl p-5 absolute max-[576px]:w-[370px] w-[380px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-50 invisible opacity-0 popup transition-all'>
                    <div className='count bg-[#FB4D4D] !top-0 !left-0 !relative inline-flex !text-xs !min-w-[26px] max-w-[26px]  max-h-[26px]'>40</div>
                    <img src={Graph22} className='my-5'/>
                    <div className='text-xs font-sfpdR text-white mt-2 mb-2'>0X5C0....5950C55</div>
                    <div className='grid grid-cols-12'>
                      <div className='col-span-3'>
                        <div className='text-[#A6D7F5] text-[11px]'>$12,948.47</div>
                        <div className='text-white/40 text-[10px]'>APPT</div>
                      </div>
                      <div className='col-span-6'>
                        <div className='text-[#A6D7F5] text-[11px]'>2 days ago</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                      <div className='col-span-3 text-end'>
                        <div className='text-[#A6D7F5] text-[11px]'>$507,582.06</div>
                        <div className='text-white/40 text-[10px]'>Last trade</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </>
  );
};

export default CardStack;
