import React, { useRef, useState } from 'react';

const faqs = [
    {
        id: 1,
        header: "What types of investment strategies do you offer?",
        text: `We provide active trading strategies for the cryptocurrency market, enabling you to profit from rising cryptocurrency values. Hyperion meticulously analyzes every active trader in the crypto space to identify the world’s best trading strategies.`
    },
    {
        id: 2,
        header: "How do you ensure the security of my investments?",
        text: `Strong Encryption like AES-256, Firewalls both from blockchain and Web application firewall preventing DOS, DDOS attacks. In addition we employ best practices for Cloud Security and Secure Coding, Secure APIs, Containerization, IDS, Security Audits.`
    },
    {
        id: 3,
        header: "What is the minimum amount required to start investing?",
        text: `There is no minimum investment required to invest through Hyperion.`
    },
    {
        id: 4,
        header: "Can I track my investments in real-time?",
        text: `Yes, we track your returns and the value of your investments in real-time.`
    },
    {
        id: 5,
        header: "How can I withdraw my funds?",
        text: `The fund stays within your crypto wallet. You can withdraw the funds directly.`
    },
    {
        id: 6,
        header: "Do you provide any educational resources?",
        text: `Yes, a comprehensive education section is offered for beginner crypto traders, and tips on how to use the platform to its full capacity.`
    },
    {
        id: 7,
        header: "What kind of support do you offer?",
        text: `We offer 24/7 support through email or chat.`
    }
];

const AccordionItem = (props) => {
    const contentEl = useRef(null);
    const { handleToggle, active, faq } = props;
    const { header, id, text } = faq;

    return (
        <>
            <div className="accordion-header">
                <div className={`accordion-toggle ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h3 className="accordion-title mt-3">{header}</h3>
                    <i className="fa fa-chevron-down accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current?.scrollHeight }
                    : { height: "0px" }
                }>
                <div className="accordion-body">
                    <p className='mb-0'>{text}</p>
                </div>
            </div>
        </>
    );
};

function Faq(props) {
    const [active, setActive] = useState(faqs[0].id);
    const handleToggle = (id) => {
        if (active === id) {
            setActive(null);
        } else {
            setActive(id);
        }
    }    
    return (
        <>
            {faqs.map((faq) => (
                <AccordionItem key={faq.id} active={active} handleToggle={handleToggle} faq={faq} />
            ))}
        </>
    );
}

export default Faq