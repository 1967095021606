import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Component/Layout';
import Home from "./Pages/Home";
import Team from "./Pages/Team";
// import Home2 from "./Pages/Home2";
function App() {
  return (
    <>
     <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route  path="/core-team" element={<Team />} />
            {/* <Route  path="/home2" element={<Home2 />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
