import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import WhiteLogo from '../assets/images/white-logo.svg'
import AppStore from '../assets/images/appstore.png'
import PlayStore from '../assets/images/playstore.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Footer() {
  useEffect(() => {
    AOS.init();
  }, [])
  
  return (
    <>
      <footer className='bg-[#000000] py-6 lg:py-16 px-4 lg:px-8 xl:px-0'>
        <div className='max-w-screen-xl mx-auto'>
          <div className='md:grid grid-cols-6 md:grid-cols-12 flex flex-col justify-center md:items-start'>
            <div className='md:col-span-4 flex justify-center md:justify-start'>
              <a href="" className='footer-logo flex flex-col text-[16px] lg:text-[23px] justify-center text-white items-center gap-y-1'>
                <img src={WhiteLogo} className='w-6 lg:w-10'/>
                HYPERION
              </a>
            </div>
            <div className='flex-1 items-center justify-center font-sfpdR flex flex-col md:col-span-4 md:mt-3 mb-2 md:my-0'> 
              <div className='[&_a]:text-[rgb(255,255,255,.65)] hover:[&_a]:text-white [&_a]:transition-all flex gap-x-2 lg:gap-x-4 mb-2 md:mb-3 col-span-4 justify-center md:justify-end mt-4 md:mt-2'>
                <Link>Privacy</Link>
                <span className='text-[rgb(255,255,255,.5)] mx-2'>|</span>
                <Link>Terms</Link>
              </div>
            </div>
            <div className='social lg:gap-6 md:col-span-4'>
              <div className='flex items-center gap-4 justify-center md:justify-end'>
                <Link>
                  <svg id="Layer_1" height="28" viewBox="0 0 512 512" width="28" fill='#fff' xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m255.1824 7.7579q69.23.79 125.0851 34.0293a249.7343 249.7343 0 0 1 88.8909 89.4346q33.0371 56.1914 33.8244 125.8428-1.962 95.3-60.1167 162.791c-38.77 44.9941-88.425 72.83-139.8274 83.5009v-178.1275h48.5969l10.99-70h-73.5869v-45.8481a39.844 39.844 0 0 1 8.4739-26.3232q8.8275-11.2527 31.0895-11.8287h44.4366v-61.3184q-.9563-.3077-18.15-2.4336a360.7434 360.7434 0 0 0 -39.16-2.4336q-44.4328.205-70.2811 25.0674-25.8488 24.8554-26.4085 71.9209v53.1973h-56v70h56v178.1275c-63.1149-10.6709-112.77-38.5068-151.54-83.5009s-58.808-99.2578-60.1162-162.791q.7851-69.6546 33.8236-125.8428a249.7389 249.7389 0 0 1 88.8917-89.4346q55.8534-33.2329 125.0842-34.0293z" fillRule="evenodd"/></svg>
                </Link>
                <Link>
                  <svg height="28" viewBox="0 0 176 176" width="28" fill='#fff' xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="linkedin"><path id="icon" d="m152 0h-128a24 24 0 0 0 -24 24v128a24 24 0 0 0 24 24h128a24 24 0 0 0 24-24v-128a24 24 0 0 0 -24-24zm-92 139.28a3.71 3.71 0 0 1 -3.71 3.72h-15.81a3.71 3.71 0 0 1 -3.72-3.72v-66.28a3.72 3.72 0 0 1 3.72-3.72h15.81a3.72 3.72 0 0 1 3.71 3.72zm-11.62-76.28a15 15 0 1 1 15-15 15 15 0 0 1 -15 15zm94.26 76.54a3.41 3.41 0 0 1 -3.42 3.42h-17a3.41 3.41 0 0 1 -3.42-3.42v-31.05c0-4.64 1.36-20.32-12.13-20.32-10.45 0-12.58 10.73-13 15.55v35.86a3.42 3.42 0 0 1 -3.37 3.42h-16.42a3.41 3.41 0 0 1 -3.41-3.42v-66.87a3.41 3.41 0 0 1 3.41-3.42h16.42a3.42 3.42 0 0 1 3.42 3.42v5.78c3.88-5.83 9.63-10.31 21.9-10.31 27.18 0 27 25.38 27 39.32z"/></g></g></svg>
                </Link>
                <Link>
                  <svg id="Capa_1" enableBackground="new 0 0 512 512" fill='#fff' height="28" viewBox="0 0 512 512" width="28" xmlns="http://www.w3.org/2000/svg"><g><path d="m480.32 128.39c-29.22 0-56.18-9.68-77.83-26.01-24.83-18.72-42.67-46.18-48.97-77.83-1.56-7.82-2.4-15.89-2.48-24.16h-83.47v228.08l-.1 124.93c0 33.4-21.75 61.72-51.9 71.68-8.75 2.89-18.2 4.26-28.04 3.72-12.56-.69-24.33-4.48-34.56-10.6-21.77-13.02-36.53-36.64-36.93-63.66-.63-42.23 33.51-76.66 75.71-76.66 8.33 0 16.33 1.36 23.82 3.83v-62.34-22.41c-7.9-1.17-15.94-1.78-24.07-1.78-46.19 0-89.39 19.2-120.27 53.79-23.34 26.14-37.34 59.49-39.5 94.46-2.83 45.94 13.98 89.61 46.58 121.83 4.79 4.73 9.82 9.12 15.08 13.17 27.95 21.51 62.12 33.17 98.11 33.17 8.13 0 16.17-.6 24.07-1.77 33.62-4.98 64.64-20.37 89.12-44.57 30.08-29.73 46.7-69.2 46.88-111.21l-.43-186.56c14.35 11.07 30.04 20.23 46.88 27.34 26.19 11.05 53.96 16.65 82.54 16.64v-60.61-22.49c.02.02-.22.02-.24.02z"/></g></svg>
                </Link>
                <Link>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" className='w-5 lg:w-auto'>
                      <defs>
                        <clipPath id="clipPath">
                          <rect id="Rectangle_20945" data-name="Rectangle 20945" width="28" height="29" transform="translate(0 -0.071)" fill="#fff"/>
                        </clipPath>
                      </defs>
                      <g id="_11244080_x_twitter_elon_musk_twitter_new_logo_icon_1_" data-name="11244080_x_twitter_elon musk_twitter new logo_icon (1)" transform="translate(0 0.071)" clipPath="url(#clipPath)">
                        <path id="Path_73961" data-name="Path 73961" d="M16.923,12.305,27.509,0H25L15.809,10.684,8.467,0H0L11.1,16.157,0,29.061H2.509l9.707-11.283,7.753,11.283h8.467L16.923,12.305ZM13.487,16.3,12.362,14.69l-8.95-12.8H7.266L14.488,12.22l1.125,1.609L25,27.258H21.149L13.487,16.3Z" transform="translate(0 -0.146)" fill="#fff"/>
                      </g>
                    </svg>
                </Link>
                <Link>
                  <svg id="Instagram" xmlns="http://www.w3.org/2000/svg" width="33.383" height="33.415" viewBox="0 0 33.383 33.415" className='w-6 lg:w-auto'>
                    <g id="Instagram-2" data-name="Instagram">
                      <path id="Path" d="M5.9,0a5.9,5.9,0,1,0,5.9,5.9A5.9,5.9,0,0,0,5.9,0Z" transform="translate(10.79 10.81)" fill="#fff"/>
                      <path id="Shape" d="M16.692,33.415a85.95,85.95,0,0,1-9.5-.524,7.582,7.582,0,0,1-4.458-2.126A7.457,7.457,0,0,1,.562,26.335a82.75,82.75,0,0,1,0-19.254,7.46,7.46,0,0,1,2.174-4.43A7.582,7.582,0,0,1,7.194.524a86.307,86.307,0,0,1,19,0,7.582,7.582,0,0,1,4.457,2.126,7.459,7.459,0,0,1,2.175,4.43,82.928,82.928,0,0,1,0,19.254,7.457,7.457,0,0,1-2.175,4.429,7.583,7.583,0,0,1-4.457,2.126A85.95,85.95,0,0,1,16.692,33.415Zm0-25.326a8.619,8.619,0,1,0,8.626,8.62A8.632,8.632,0,0,0,16.691,8.089Zm9.08-2.268a1.814,1.814,0,1,0,1.816,1.814A1.817,1.817,0,0,0,25.772,5.82Z" fill="#fff"/>
                    </g>
                  </svg>
                </Link>
              </div>
              <div className='flex items-center gap-x-4 md:gap-x-6 mt-6 md:mt-3 lg:mt-6 justify-center md:justify-end'>
                <Link><img src={AppStore} alt="App Store" width={150} className='border rounded-md border-[#555555]'/></Link>
                <Link><img src={PlayStore} alt="Play Store" width={150} className='border rounded-md border-[#555555]'/></Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer