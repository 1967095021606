import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
const InnerOutlet = () => {
  let location = useLocation();
  location = location.pathname;
  return (
    <>
        <Header position={location === '/'  ? 'fixed-top' : 'sticky-top'} />
          <div className='flex-1'>
            <Outlet/>
          </div>
        <Footer/>
    </>
  )
}

export default InnerOutlet;