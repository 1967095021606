import React, { useEffect,useState  } from 'react';
import Logo from '../assets/images/logo.svg'
import { Link, useLocation } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Header() {
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    useEffect(() => {
        setUrl(location.pathname);
        AOS.init();
    }, [location]);  
  return (
    <>
        <header className='bg-[#e2edf3]'>
            <nav className="py-4 md:py-8 border-b-[1px] border-[#C0CFD3] max-w-screen-xl mx-auto px-3" data-aos="fade-up">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <Link to='/' className="flex items-center" data-aos="fade-right" data-aos-delay="300">
                        <img src={Logo} className="mr-2 h-5 sm:mr-3 sm:h-9" alt="Flowbite Logo" />
                        <span className="self-center text-md md:text-xl font-semibold whitespace-nowrap">HYPERION</span>
                    </Link>
                    <div className="flex items-center lg:order-2 uppercase text-[13px] md:text-lg [&_a]:transition-all slideInRight font-sfpdR" data-aos="fade-left"  data-aos-delay="300">
                        <div className='flex flex-col items-center relative'>
                            <Link href="#" className="rounded-full md:px-4 lg:px-7 py-1 lg:py-2 mr-2 focus:outline-none">API</Link>
                            {/* <span className='bg-black rounded-full text-white text-[10px] inline-table text-center px-2.5 absolute -bottom-3.5'>Coming Soon</span> */}
                        </div>
                        <div className='flex flex-col items-center relative'>
                            <Link href="#" className="rounded-full md:px-4 lg:px-7 py-1 lg:py-2 mr-2 focus:outline-none">MATCHMAKING</Link>
                            {/* <span className='bg-black rounded-full text-white text-[10px] inline-table text-center px-2.5 absolute -bottom-3.5'>Coming Soon</span> */}
                        </div>
                        {/* <Link to='#' className={"rounded-full px-4 lg:px-7 py-1 lg:py-2 mr-2 focus:outline-none" + (url === "/core-team" ?" bg-black text-white" : "text-gray-800 hover:bg-black hover:text-white")}>Team</Link> */}
                        <Link href="#" className="rounded-full md:px-4 lg:px-7 py-1 lg:py-2 mr-2 focus:outline-none">Team</Link>
                        <Link href="#" className="text-white hover:bg-[#A6D7F5] hover:text-black px-4 lg:px-7 py-1 lg:py-2 mr-2 focus:outline-none bg-[#000000] rounded-full">App</Link>
                    </div>
                </div>
            </nav>
        </header>
    </>
  )
}

export default Header